import { useState } from 'react';
import './People.scss';
import { Header } from 'components/Shared/Header/Header';
import { PersonTilesList } from 'components/Shared/PersonTilesList/PersonTilesList';
import { Person, ResponsibilityTag } from 'types/Shared/Person.type';
import peopleData from 'data/people.json';
import { PersonTile } from 'components/Shared/PersonTile/PersonTile';
import { filterPeopleByTag } from 'utils/People/filter-people-by-tag/filter-people-by-tag';
import { Helmet } from 'react-helmet';

export const People: React.FC = () => {
  const [responsibilities] = useState<string[]>(Object.values(ResponsibilityTag));
  const [selectedResponsibility, setSelectedResponsibility] = useState<string>('wszyscy');
  const [people, setPeople] = useState<Person[]>(peopleData as Person[]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedResponsibility(event.target.value);
    if (event.target.value === 'wszyscy') return setPeople(peopleData as Person[]);
    setPeople(filterPeopleByTag(peopleData as Person[], event.target.value as ResponsibilityTag));
  };

  return (
    <>
      <Helmet>
        <title>Ludzie</title>
        <meta name='description' content='Osoby z naszego duszpasterstwa. Zobacz kto jest za co odpwowiedzialny.' />
      </Helmet>
      <Header />
      <main>
        <section className='people'>
          <div className='people__wrapper'>
            <div className='people__title-wrapper'>
              <h2 className='people__title'>Nasza ekipa</h2>
            </div>
            <form className='people-form'>
              <div className='people-form__wrapper'>
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
                  checked={selectedResponsibility === 'wszyscy'}
                  value='wszyscy'
                  id='responsibilities-default'
                  name='responsibilities'
                  type='radio'
                  className='people-form__input'
                />
                <label htmlFor='responsibilities-default' className='people-form__label'>
                  Wszyscy
                </label>
              </div>
              {responsibilities.map((element: string, index: number) => (
                <div key={element} className='people-form__wrapper'>
                  <input
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
                    checked={selectedResponsibility === element}
                    value={element}
                    id={`responsibilities-${index}`}
                    name='responsibilities'
                    type='radio'
                    className='people-form__input'
                  />

                  <label htmlFor={`responsibilities-${index}`} className='people-form__label'>
                    {element}
                  </label>
                </div>
              ))}
            </form>

            <PersonTilesList>
              {people.map((element: Person) => (
                <PersonTile key={element.id} person={element} />
              ))}
            </PersonTilesList>
          </div>
        </section>
      </main>
    </>
  );
};
