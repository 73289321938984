import * as React from 'react';
import './Footer.scss';

export const Footer: React.FC = () => {
  return (
    <footer className='footer'>
      <div className='footer__wrapper'>
        <p className='footer__text'>
          <span>Wykonane przez</span>{' '}
          <a
            className='footer__link'
            target='_blank'
            rel='noreferrer'
            href='https://piotrsliwka333.github.io/Portfolio-management-page/'
          >
            Piotr Śliwka
          </a>{' '}
          <span>z miłości do</span> <span className='footer__text_decoration_extra'>Pana Jezusa Chrystusa</span>{' '}
          <img className='footer__img' src='/assets/images/Piotr-Sliwka-sign.svg' alt='podpis' />
        </p>
        <small className='footer__copyright'>© 2023 Horeb. Wszelkie prawa zastrzeżone</small>
      </div>
    </footer>
  );
};
