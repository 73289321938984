import * as React from 'react';
import './Calendar.scss';
import { useMediaQuery } from 'hooks/useMediaQuery/useMediaQuery';
import { Header } from 'components/Shared/Header/Header';
import { Helmet } from 'react-helmet';

export const Calendar: React.FC = () => {
  const lgUp = useMediaQuery('(min-width: 1200px)');

  return (
    <>
      <Helmet>
        <title>Kalendarz</title>
        <meta name='description' content='Kalendarz w który dowiesz się o naszych nadchodzących wydarzeniach' />
      </Helmet>
      <Header />
      <main>
        <section className='calendar-container'>
          <div className='calendar-container__wrapper'>
            <div className='calendar-container__title-wrapper'>
              <h2 className='calendar-container__title'>Kalendarz</h2>
            </div>
            <iframe
              title='calendar'
              className='calendar'
              frameBorder='0'
              src={
                lgUp
                  ? 'https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FWarsaw&showTitle=0&showPrint=0&showTz=0&showCalendars=0&showTabs=0&showDate=1&showNav=1&mode=MONTH&title=test&src=c2RhaG9yZWJAZ21haWwuY29t&src=OXA5dDlubzJvY3N2aWJoMjUzNDY5MXZsNjhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZW4ucG9saXNoI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23F6BF26&color=%23C0CA33&color=%230B8043'
                  : 'https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FWarsaw&showTitle=0&showPrint=0&showTz=0&showCalendars=0&showTabs=0&showDate=1&showNav=0&mode=AGENDA&title=test&src=c2RhaG9yZWJAZ21haWwuY29t&src=OXA5dDlubzJvY3N2aWJoMjUzNDY5MXZsNjhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZW4ucG9saXNoI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23F6BF26&color=%23C0CA33&color=%230B8043'
              }
              scrolling='no'
            ></iframe>
          </div>
        </section>
      </main>
    </>
  );
};
