import { Hero } from 'components/Home/Hero/Hero';
import './Home.scss';
import { NewsFeed } from 'components/Home/NewsFeed/NewsFeed';
import { Header } from 'components/Shared/Header/Header';
import { Footer } from 'components/Shared/Footer/Footer';
import { Helmet } from 'react-helmet';

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>SDA Horeb</title>
        <meta name='description' content='SDA Horeb - Salezjanskie duszpasterstwo akademickie Horeb we Wrocławiu' />
      </Helmet>
      <Header />
      <main className='home-container'>
        <Hero />
        <NewsFeed />
      </main>
      <Footer />
    </>
  );
};
