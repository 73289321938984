import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import './PersonTileModal.scss';
import { Responsibility } from 'types/Shared/Person.type';

export const PersonTileModal: React.FC<OwnProps> = (props: OwnProps) => {
  const { open, avatarImage, name, surname, bio, phoneNumber, email, responsibilities, onClose } = props;

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    }

    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, [open]);

  const handleClickPeronTileModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  if (!open) return null;
  return createPortal(
    <div className='overlay' onClick={onClose}>
      <div className='person-tile-modal' onClick={handleClickPeronTileModal}>
        <div className='person-tile-modal__header'>
          <div className='avatar'>
            <img className='avatar__image' src={avatarImage} alt={`${name}${surname}`} />
          </div>
        </div>
        <div className='person-tile-modal__content'>
          <div className='person-tile-modal__title-wrapper'>
            <h4 className='person-tile-modal__title'>
              {name} {surname}
            </h4>
          </div>
          <p className='person-tile-modal__description'>{bio}</p>
          <ul className='responsibilities-list'>
            {responsibilities.map((element: Responsibility) => (
              <li key={element.id} className='responsibilities-list__item'>
                {element.icon && <FontAwesomeIcon className='responsibilities-list__icon' icon={element.icon} />}
                <div className='responsibilities-list__wrapper'>
                  <p className='responsibilities-list__name'>{element.name}</p>
                  {element.responsibilityFrequency && (
                    <p className='responsibilities-list__description'>{element.responsibilityFrequency}</p>
                  )}
                </div>
              </li>
            ))}
          </ul>
          {(email || phoneNumber) && (
            <ul className='contact-list'>
              {email && (
                <li className='contact-list__item'>
                  <a className='contact-list__link' href={`tel:${email}`}>
                    <FontAwesomeIcon className='contact-list__icon' icon={faPhone} />
                    <span className='contact-list-item__text'>{phoneNumber}</span>
                  </a>
                </li>
              )}
              {phoneNumber && (
                <li className='contact-list__item'>
                  <a className='contact-list__link' href={`mailto:${email}`}>
                    <FontAwesomeIcon className='contact-list__icon' icon={faEnvelope} />
                    <span className='contact-list-item__text'>{email}</span>
                  </a>
                </li>
              )}
            </ul>
          )}
          <div className='person-tile-modal__button-wrapper'>
            <button onClick={onClose} className='person-tile-modal-button'>
              <span className='person-tile-modal-button__text'>Zamknij</span>
              <FontAwesomeIcon icon={faXmark} className='person-tile-modal-button__icon' />
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('root') as Element,
  );
};

interface OwnProps {
  open: boolean;
  avatarImage: string;
  name: string;
  surname: string;
  bio: string;
  responsibilities: Responsibility[];
  phoneNumber: string | null;
  email: string | null;
  onClose: () => void;
}
