import * as React from 'react';
import './Hero.scss';
import { Activity } from 'types/Home/activity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookBible, faChurch, faClapperboard, faGuitar, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'hooks/useMediaQuery/useMediaQuery';
import { Link } from 'react-router-dom';
import { Button } from 'components/Shared/Button/Button';

export const Hero: React.FC = () => {
  const smUp = useMediaQuery('(min-width: 600px)');

  const activitiesList: Activity[] = [
    {
      id: '2',
      icon: faBookBible,
      name: 'Lectio Divina - Analiza słowa Bożego',
      description: 'Poniedziałek 19:30',
      navLink: '/aktywnosci#lectio-divina',
    },
    {
      id: '3',
      icon: faChurch,
      name: 'Msza duszpasterska',
      description: 'Środa 20:00',
      navLink: '/aktywnosci#msza-duszpasterska',
    },
    {
      id: '4',
      icon: faClapperboard,
      name: 'Wydarzenia kulturalno-sportowe: planszówki, siatkówka...',
      description: 'Czwartek 20:00, Piątek 20:00',
      navLink: '/aktywnosci#wydarzenia-kulturalno-sportowe',
    },
    {
      id: '5',
      icon: faGuitar,
      name: 'Schola',
      description: 'Niedziela 18:00',
      navLink: '/aktywnosci#schola',
    },
    {
      id: '6',
      icon: faChurch,
      name: 'Msza akademicka + próba służby liturgicznej',
      description: 'Niedziela 19:15 (Próba służby o 19:00)',
      navLink: '/aktywnosci#msza-akademicka',
    },
  ];

  return (
    <section className='hero-container'>
      <div className='hero-container__wrapper'>
        <div className='hero-container__content'>
          {smUp ? (
            <h1 className='hero-container__title'>
              Cześć,
              <br />
              Witamy na <span className='hero-container__title_decoration_underlined'>Horebie</span>
            </h1>
          ) : (
            <h1 className='hero-container__title'>
              Cześć, Witamy na <span className='hero-container__title_decoration_underlined'>Horebie</span>
            </h1>
          )}

          <p className='hero-container__description'>Salezjańskie duszpasterstwo akademickie we Wrocławiu.</p>
          {/* https://en.bem.info/methodology/quick-start/#nesting - regarding class naming in this particualr situation*/}
          <ul className='actvities-list'>
            {activitiesList.map((element: Activity) => (
              <li key={element.id} className='activities-list__item activities-list-item'>
                <Link className='activities-list-item__link' to={element.navLink}>
                  <FontAwesomeIcon className='activities-list-item__icon' icon={element.icon} />
                  <p className='activities-list-item__name'>{element.name}</p>
                  <p className='activities-list-item__description'>{element.description}</p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className='hero-container__button-wrapper'>
          <Button href='#nasze-wydarzenia' text='Dowiedz się więcej' iconAtTheEnd={faAnglesDown} />
        </div>
      </div>
    </section>
  );
};
