import * as React from 'react';
import './AboutUsHero.scss';
import { Button } from 'components/Shared/Button/Button';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'hooks/useMediaQuery/useMediaQuery';

export const AboutUsHero: React.FC = () => {
  const lgUp = useMediaQuery('(min-width: 1200px)');

  return (
    <section className='about-us-container'>
      <div className='about-us-container__wrapper'>
        <div className='about-us-container__content'>
          <h1 className='about-us-container__title'>
            Czym w ogóle jest <span className='about-us-container__title_decoration_underlined'>Horeb ?</span>
          </h1>

          <p className='about-us-container__description'>{lgUp ? 'Tam na prawo. To my :)' : 'Tam na dole. Ty my :)'}</p>
        </div>
      </div>
      <div className='about-us-container__button-wrapper'>
        <Button href='#horeb' text='Dowiedz się więcej' iconAtTheEnd={faAnglesDown} />
      </div>
    </section>
  );
};
