import { AboutUsHero } from 'components/AboutUs/AboutUsHero/AboutUsHero';
import { Informations } from 'components/AboutUs/Informations/Informations';
import { Footer } from 'components/Shared/Footer/Footer';
import { Header } from 'components/Shared/Header/Header';
import * as React from 'react';
import './AboutUs.scss';
import { Helmet } from 'react-helmet';

export const AboutUs: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>O nas</title>
        <meta name='description' content='Dowiedz się więcej o nas' />
      </Helmet>
      <Header />
      <main className='about-us'>
        <AboutUsHero />
        <Informations />
      </main>
      <Footer />
    </>
  );
};
