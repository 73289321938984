import './Contact.scss';
import { Header } from 'components/Shared/Header/Header';
import peopleData from '../../data/people.json';
import { Person } from 'types/Shared/Person.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Footer } from 'components/Shared/Footer/Footer';
import { filterPeopleById } from 'utils/People/filter-people-by-id/filter-people';
import { Helmet } from 'react-helmet';

export const Contact: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Kontakt</title>
        <meta name='description' content='Jeśli masz pytanie skontaktuj się z kadrą duszpasterstwa' />
      </Helmet>
      <Header />
      <main>
        <section className='contact'>
          <div className='contact__title-wrapper'>
            <h2 className='contact__title'>Kontakt</h2>
          </div>

          <article className='contact-single'>
            <div className='contact-single__title-wrapper'>
              <h3 className='contact-single__title'>Kontakt do osób odpowiedzialnych</h3>
            </div>
            <div className='contact-single__list-wrapper'>
              <ul className='contacts-list'>
                {[
                  filterPeopleById(peopleData as Person[], '1'),
                  filterPeopleById(peopleData as Person[], '3'),
                  filterPeopleById(peopleData as Person[], '4'),
                ].map((element: Person) => (
                  <li key={element.id} className='contacts-list__item'>
                    <div className='avatar'>
                      <img
                        className='avatar__image'
                        src={element.avatarImage}
                        alt={`${element.name} ${element.surname}`}
                      />
                    </div>
                    <div className='contacts-list__wrapper'>
                      <p className='contacts-list__name'>{`${element.name} ${element.surname}`}</p>
                      <a href={`tel:${element.phoneNumber}`} className='contacts-list__link'>
                        <FontAwesomeIcon className='contacts-list__icon' icon={faPhone} />
                        <span>{element.phoneNumber}</span>
                      </a>
                      <a href={`mailto:${element.email}`} className='contacts-list__link'>
                        <FontAwesomeIcon className='contacts-list__icon' icon={faEnvelope} />
                        <span>{element.email}</span>
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </article>
          <article className='contact-single'>
            <div className='contact-single__title-wrapper'>
              <h3 className='contact-single__title'>Social Media</h3>
            </div>
            <div className='contact-single__list-wrapper'>
              <ul className='social-media-list'>
                <li className='social-media-list__item'>
                  <a href='https://www.facebook.com/DaHoreb' className='social-media-list__link'>
                    <FontAwesomeIcon className='social-media-list__icon' icon={faSquareFacebook} />
                    <span className='social-media-list__text'>SDA Horeb Strona</span>
                  </a>
                </li>
                <li className='social-media-list__item'>
                  <a href='https://www.facebook.com/groups/205104059558565/' className='social-media-list__link'>
                    <FontAwesomeIcon className='social-media-list__icon' icon={faSquareFacebook} />
                    <span className='social-media-list__text'>SDA Horeb Grupa</span>
                  </a>
                </li>
              </ul>
            </div>
          </article>
          <article className='contact-single'>
            <div className='contact-single__title-wrapper'>
              <h3 className='contact-single__title'>Lokalizacja</h3>
            </div>
            <div className='contact-single__list-wrapper'>
              <ul className='location-list'>
                <li className='location-list__item'>
                  <div className='location-list__wrapper'>
                    <FontAwesomeIcon className='location-list__icon' icon={faLocationDot} />
                    <span className='location-list__text'>Bolesława Prusa 78</span>
                  </div>
                  <iframe
                    title='google-map'
                    className='location-list__iframe'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1489.0917907626192!2d17.049972107771513!3d51.12056439409069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe9cfe23ba2ab%3A0x128cadac7f5e78dc!2sSDA%20Horeb!5e0!3m2!1sen!2spl!4v1698138811521!5m2!1sen!2spl'
                    loading='lazy'
                  ></iframe>
                </li>
                <li className='location-list__item'>
                  <div className='location-list__wrapper'>
                    <FontAwesomeIcon className='location-list__icon' icon={faLocationDot} />
                    <span className='location-list__text'>Obok kościoła Michała Archanioła</span>
                  </div>
                  <img
                    className='location-list__img'
                    src='/assets/images/location-hint-1.png'
                    alt='mapa wskazówka dojścia 1'
                  />
                </li>
                <li className='location-list__item'>
                  <div className='location-list__wrapper'>
                    <FontAwesomeIcon className='location-list__icon' icon={faLocationDot} />
                    <span className='location-list__text'>Jak wejść</span>
                  </div>
                  <img
                    className='location-list__img'
                    src='/assets/images/location-hint-2.png'
                    alt='mapa wskazówka dojścia 2'
                  />
                </li>
              </ul>
            </div>
          </article>
        </section>
      </main>
      <Footer />
    </>
  );
};
