import './SinglePlan.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLocationDot, faPhone, IconDefinition } from '@fortawesome/free-solid-svg-icons';

export const SinglePlan: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    id,
    dayTitle,
    contentIcon,
    contentTitle,
    contentDescription,
    contentFrequencyDescription,
    location,
    contactPhone,
    contentImageSrc,
    infoPointOne,
    infoPointTwo,
    infoPointThree,
  } = props;

  return (
    <li className='single-plan' id={id}>
      {dayTitle && <h3 className='single-plan__title'>{dayTitle}</h3>}

      <div className='single-plan__wrapper'>
        <div className='single-plan__info'>
          <div className='info-content'>
            <div className='info-content__icon-wrapper'>
              <FontAwesomeIcon className='info-content__icon' icon={contentIcon} />
            </div>
            <div className='info-content__wrapper'>
              <h4 className='info-content__title'>{contentTitle}</h4>
              <p className='info-content__description'>{contentDescription}</p>
              <ul className='info-content__description-points-list'>
                <li className='description-points-list-element'>
                  <FontAwesomeIcon className='description-points-list-element__icon' icon={faClock} />
                  <span className='description-points-list-element__text'>{contentFrequencyDescription}</span>
                </li>
                <li className='description-points-list-element'>
                  <FontAwesomeIcon className='description-points-list-element__icon' icon={faLocationDot} />
                  <span className='description-points-list-element__text'>{location}</span>
                </li>
                <li className='description-points-list-element'>
                  <FontAwesomeIcon className='description-points-list-element__icon' icon={faPhone} />
                  <span className='description-points-list-element__text'>{contactPhone}</span>
                </li>
              </ul>
              <div className='info-content__image-wrapper'>
                <img src={contentImageSrc} alt={contentTitle} className='info-content__image' />
              </div>
            </div>
          </div>
          <ul className='info-points-list'>
            <li className='info-points-list-item'>
              <p className='info-points-list-item__wrapper'>
                <span className='info-points-list-item__counter'>1</span>
                <span className='info-points-list-item__text'>{infoPointOne}</span>
              </p>
            </li>
            <li className='info-points-list-item'>
              <p className='info-points-list-item__wrapper'>
                <span className='info-points-list-item__counter'>2</span>
                <span className='info-points-list-item__text'>{infoPointTwo}</span>
              </p>
            </li>
            <li className='info-points-list-item'>
              <p className='info-points-list-item__wrapper'>
                <span className='info-points-list-item__counter'>3</span>
                <span className='info-points-list-item__text'>{infoPointThree}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
};

interface OwnProps {
  id: string;
  dayTitle?: string;
  contentIcon: IconDefinition;
  contentTitle: string;
  contentDescription: string;
  contentFrequencyDescription: string;
  location: string;
  contactPhone: string;
  contentImageSrc: string;
  infoPointOne: string;
  infoPointTwo: string;
  infoPointThree: string;
}
