import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home } from 'pages/Home/Home';
import 'scss/main.scss';
import { Calendar } from 'pages/Calendar/Calendar';
import { Activities } from 'pages/Activities/Activities';
import { AboutUs } from 'pages/AboutUs/AboutUs';
import { People } from 'pages/People/People';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHandshakeAngle,
  faBible,
  faHandsPraying,
  faClapperboard,
  faMountainSun,
  faChurch,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import { Contact } from 'pages/Contact/Contact';

library.add(faHandshakeAngle, faBible, faHandsPraying, faClapperboard, faMountainSun, faChurch, faUtensils);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/kalendarz',
    element: <Calendar />,
  },
  {
    path: '/aktywnosci',
    element: <Activities />,
  },
  {
    path: '/o-nas',
    element: <AboutUs />,
  },
  {
    path: '/ludzie',
    element: <People />,
  },
  {
    path: '/kontakt',
    element: <Contact />,
  },
]);

root.render(<RouterProvider router={router} />);
