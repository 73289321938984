import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useMediaQuery } from 'hooks/useMediaQuery/useMediaQuery';
import { NavLink } from 'react-router-dom';
import './Header.scss';

export const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const lgUp = useMediaQuery('(min-width: 1200px)');

  const handleOpenNav = () => {
    setIsNavOpen(true);
  };

  const handleCloseNav = () => {
    setIsNavOpen(false);
  };

  return (
    <header className='header'>
      <div className='header__wrapper'>
        {!lgUp && (
          <div className='hamburger-container'>
            <FontAwesomeIcon onClick={handleOpenNav} className='hamburger' icon={faBars} />
          </div>
        )}

        {/* <div className="logo"></div> */}
        {(isNavOpen || lgUp) && (
          <nav className='nav'>
            <ul className='nav-list'>
              <li className='nav-list-item'>
                <NavLink className='nav-list-item__link' to='/'>
                  Home
                </NavLink>
              </li>
              <li className='nav-list-item'>
                <NavLink className='nav-list-item__link' to='/kalendarz'>
                  Kalendarz
                </NavLink>
              </li>
              <li className='nav-list-item'>
                <NavLink className='nav-list-item__link' to='/aktywnosci'>
                  Aktywności
                </NavLink>
              </li>
              <li className='nav-list-item'>
                <NavLink className='nav-list-item__link' to='/o-nas'>
                  O nas
                </NavLink>
              </li>
              <li className='nav-list-item'>
                <NavLink className='nav-list-item__link' to='/ludzie'>
                  Ludzie
                </NavLink>
              </li>
              <li className='nav-list-item'>
                <NavLink className='nav-list-item__link' to='/kontakt'>
                  Kontakt
                </NavLink>
              </li>
              {!lgUp && (
                <li className='nav-list-item'>
                  <button onClick={handleCloseNav} className='nav-list-item__button'>
                    <FontAwesomeIcon icon={faClose} />
                  </button>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};
