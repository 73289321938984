import { useEffect } from 'react';
import * as React from 'react';
import './WeeklyPlan.scss';
import { SinglePlan as SinglePlanType } from 'types/Activities/single-plan.type';
import { SinglePlan } from '../Common/SinglePlan/SinglePlan';
import { faBible, faChurch, faClapperboard, faGuitar } from '@fortawesome/free-solid-svg-icons';

export const WeeklyPlan: React.FC = () => {
  useEffect(() => {
    const href = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const weeklyPlanList: SinglePlanType[] = [
    {
      id: 'lectio-divina',
      dayTitle: 'Poniedziałek',
      contentTitle: 'Lectio Divina - Analiza słowa Bożego',
      contentIcon: faBible,
      contentDescription:
        'Lectio divina czyli co tygodniowe spotkania, na których rozważamy i analizujemy ewangelię na kolejną niedzielę. W skrócie szukamy królestwa niebieskiego :). Przy tym dzielimy się również jak dane słowo poruszyło nas.',
      contentFrequencyDescription: 'Co tydzień w poniedziałek o 19:30',
      location: 'Bolesława Prusa 78',
      contactPhone: '508 602 643',
      contentImageSrc: '/assets/images/lectio-divina.png',
      infoPointOne: 'Jezus',
      infoPointTwo: 'Biblia',
      infoPointThree: 'Rozważanie',
    },
    {
      id: 'msza-duszpasterska',
      dayTitle: 'Środa',
      contentTitle: 'Msza duszpasterska',
      contentIcon: faChurch,
      contentDescription:
        'Msza naszego duszpasterstwa, na której spotykamy się w bardziej kameralnym gronie żeby spędzić czas z naszym panem i zbawicielem Jezusem Chrystusem :).',
      contentFrequencyDescription: 'Co tydzień w środę o 20:00',
      location: 'Kościół Michała Archanioła, Bolesława Prusa 78 Wrocław',
      contactPhone: '792 957 471',
      contentImageSrc: '/assets/images/msza-srodowa.png',
      infoPointOne: 'Eucharystia',
      infoPointTwo: 'Wspólnota',
      infoPointThree: 'Jedność',
    },
    {
      id: 'wydarzenia-kulturalno-sportowe',
      dayTitle: 'Czwartek, Piątek',
      contentTitle: 'Wydarzenia kulturalno-sportowe',
      contentIcon: faClapperboard,
      contentDescription:
        'Wydarzenia na których pogramy w planszówki, postrzela się z pistoletów (tych na laser :) jak i również zagramy mecz w siatkówkę',
      contentFrequencyDescription: 'Co tydzień w czwartek i piątek o 20:00',
      location: 'Różnie w zależności od wydarzenia',
      contactPhone: '535 520 984',
      contentImageSrc: '/assets/images/kulturalne.png',
      infoPointOne: 'Zabawa',
      infoPointTwo: 'Spotkanie',
      infoPointThree: 'Radooość',
    },
    {
      id: 'schola',
      dayTitle: 'Niedziela',
      contentTitle: 'Schola',
      contentIcon: faGuitar,
      contentDescription: 'Spotykamy się żeby uczyć się śpiewać i wychwalać Pana w radości i miłości.',
      contentFrequencyDescription: 'Co tydzień w niedziele o 18:00',
      location: 'Salka Horebowa, Bolesława Prusa 78 Wrocław',
      contactPhone: '797 548 112',
      contentImageSrc: '/assets/images/schola.png',
      infoPointOne: 'Śpiew',
      infoPointTwo: 'Radość',
      infoPointThree: 'Spotkanie',
    },
    {
      id: 'msza-akademicka',
      dayTitle: 'Niedziela',
      contentTitle: 'Msza akademicka + próba służby liturgicznej',
      contentIcon: faChurch,
      contentDescription:
        'Spotykamy się wspólnie na mszy żeby budować nasze życie na solidnym fundamencie jakim jest Jezus Chrystus.',
      contentFrequencyDescription: 'Co tydzień w niedziele o 19:15 (Próba służby o 18:45)',
      location: 'Kościół Michała Archanioła, Bolesława Prusa 78 Wrocław',
      contactPhone: '792 957 471',
      contentImageSrc: '/assets/images/msza-niedzielna.png',
      infoPointOne: 'Jezus',
      infoPointTwo: 'Kościół',
      infoPointThree: 'Wspólnota',
    },
  ];

  return (
    <section className='weekly-plan'>
      <div className='weekly-plan__title-wrapper'>
        <h2 className='weekly-plan__title' id='plan-tygodnia'>
          Plan tygodnia
        </h2>
      </div>
      <ul className='weekly-plan__list'>
        {weeklyPlanList.map((element: SinglePlanType) => (
          <SinglePlan
            id={element.id}
            key={element.id}
            dayTitle={element.dayTitle}
            contentTitle={element.contentTitle}
            contentIcon={element.contentIcon}
            contentDescription={element.contentDescription}
            contentFrequencyDescription={element.contentFrequencyDescription}
            location={element.location}
            contactPhone={element.contactPhone}
            contentImageSrc={element.contentImageSrc}
            infoPointOne={element.infoPointOne}
            infoPointTwo={element.infoPointTwo}
            infoPointThree={element.infoPointThree}
          />
        ))}
      </ul>
    </section>
  );
};
