import { PropsWithChildren } from 'react';
import * as React from 'react';
import './Button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export const Button: React.FC<OwnProps> = (props: PropsWithChildren<OwnProps>) => {
  const { text, iconAtTheEnd, secondary, href } = props;

  return (
    <a href={href} className={`btn ${secondary ? 'btn_secondary' : 'btn_primary'}`}>
      {text}
      {!!iconAtTheEnd && <FontAwesomeIcon className='btn__icon' icon={iconAtTheEnd} />}
    </a>
  );
};

interface OwnProps {
  href: string;
  text: string;
  secondary?: boolean;
  iconAtTheEnd?: IconDefinition | undefined;
}
