import { useState } from 'react';
import './PersonTile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { PersonTileModal } from './PersonTileModal/PersonTileModal';
import { Person, Responsibility } from 'types/Shared/Person.type';

export const PersonTile: React.FC<OwnProps> = (props: OwnProps) => {
  const { person } = props;

  const { name, surname, responsibilities, image, bio, email, phoneNumber, avatarImage } = person;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };

  return (
    <li className='person-tile'>
      <button onClick={handleOpenModal} className='person-tile__button'>
        <FontAwesomeIcon className='person-tile__icon' icon={faQuestion} />
      </button>
      <div className='person-tile__image-wrapper'>
        <img className='person-tile__image' src={image} alt={name} />
      </div>
      <div className='person-tile__content'>
        <p className='person-tile__name'>
          {name} {surname}
        </p>
        {responsibilities.length > 0 && (
          <ul className='responsibilities-list'>
            {responsibilities.map((element: Responsibility) => (
              <li key={element.id} className='responsibilities-list__item'>
                {element.icon && <FontAwesomeIcon className='responsibilities-list__icon' icon={element.icon} />}
                <p className='responsibilities-list__text'>{element.name}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
      <PersonTileModal
        open={isModalOpen}
        avatarImage={avatarImage}
        name={name}
        surname={surname}
        responsibilities={responsibilities}
        bio={bio}
        email={email}
        phoneNumber={phoneNumber}
        onClose={handleCloseModal}
      />
    </li>
  );
};

interface OwnProps {
  person: Person;
}
