import { IconName } from '@fortawesome/free-solid-svg-icons';

export interface Person {
  id: string;
  name: string;
  surname: string;
  image: string;
  avatarImage: string;
  responsibilities: Responsibility[];
  bio: string;
  phoneNumber: string | null;
  email: string | null;
}

export interface Responsibility {
  id: string;
  icon: IconName;
  name: string;
  responsibilityFrequency: null | string;
  tag: ResponsibilityTag;
}

export enum ResponsibilityTag {
  LECTIO_DIVINA = 'Lectio Divina',
  MUZYCZNY = 'Muzyczny',
  LSO = 'LSO',
  TURYSTYCZNY = 'Turystyczny',
  KULTURALNO_SPORTOWY = 'Kulturalno-sportowy',
  SLUGA = 'Sługa',
  ADORACJA = 'Adoracja',
  GOTOWANIE = 'Gotowanie',
}
