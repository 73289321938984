import * as React from 'react';
import './ExtraEvents.scss';
import { SinglePlan as SinglePlanType } from 'types/Activities/single-plan.type';
import { SinglePlan } from '../Common/SinglePlan/SinglePlan';
import { faMountainSun, faWater, faPeopleRoof } from '@fortawesome/free-solid-svg-icons';

export const ExtraEvents: React.FC = () => {
  const extraEventsList: SinglePlanType[] = [
    {
      id: 'oboz-w-bialym-dunajcu',
      dayTitle: 'Wyjazd wakacyjny',
      contentTitle: 'Obóz w Białym Dunajcu',
      contentIcon: faPeopleRoof,
      contentDescription:
        'Obóz Adaptacyjny Duszpasterstw Akademickich Wrocławia i Opola w Białym Dunajcu. Co roku we wrześniu aktualni studenci i maturzyści rozpoczynający dopiero studia na Wrocławskich uczelniach wybierają się do malowniczej wioski obok Zakopanego na 2 tygodnie aby przeżyć wspaniałą przygodę! Pełno wycieczek w góry, integracji, zabaw i imprez oraz obecności Boga. Obóz za główne cele stawia sobie: integrację do środowisk akademickich i Duszpasterstw Akademickich Wrocławia; tworzenie pięknych relacji',
      contentFrequencyDescription: 'Wrzesień',
      location: 'Biały Dunajec',
      contactPhone: '508 602 643',
      contentImageSrc: '/assets/images/bialy-dunajec.png',
      infoPointOne: 'Góry',
      infoPointTwo: 'Wyjazd',
      infoPointThree: 'Chata',
    },
    {
      id: 'wyjscia-w-gory',
      dayTitle: 'Sobotnie wyjazdy okazjonalne',
      contentTitle: 'Wyjścia w góry',
      contentIcon: faMountainSun,
      contentDescription: 'Jak Horeb to nie może zabraknać wyjść w góry. Co jakiś czas organizujemy wyjścia w góry.',
      contentFrequencyDescription: 'Różnie. (Warto sprawdzać wydarzenia na stronie albo fb)',
      location: 'Różnie',
      contactPhone: '508 602 643',
      contentImageSrc: '/assets/images/gory.png',
      infoPointOne: 'Góry',
      infoPointTwo: 'Góry',
      infoPointThree: 'Góry',
    },
    {
      id: 'kajaki',
      dayTitle: 'Wyjazd wakacyjny',
      contentTitle: 'Kajaki',
      contentIcon: faWater,
      contentDescription: 'Od czasu do czasu organizujemy wyprawy kajakowe.',
      contentFrequencyDescription: 'Różnie (zazwyczaj podczas wakacji).',
      location: 'Różnie w zależności od wydarzenia',
      contactPhone: '792 957 471',
      contentImageSrc: '/assets/images/kajaki.png',
      infoPointOne: 'Zabawa',
      infoPointTwo: 'Spotkanie',
      infoPointThree: 'Radooość',
    },
  ];

  return (
    <section className='extra-events'>
      <div className='extra-events__title-wrapper'>
        <h2 className='extra-events__title' id='plan-tygodnia'>
          Dodatkowe wydarzenia
        </h2>
      </div>
      <ul className='extra-events__list'>
        {extraEventsList.map((element: SinglePlanType) => (
          <SinglePlan
            id={element.id}
            key={element.id}
            dayTitle={element.dayTitle}
            contentTitle={element.contentTitle}
            contentIcon={element.contentIcon}
            contentDescription={element.contentDescription}
            contentFrequencyDescription={element.contentFrequencyDescription}
            location={element.location}
            contactPhone={element.contactPhone}
            contentImageSrc={element.contentImageSrc}
            infoPointOne={element.infoPointOne}
            infoPointTwo={element.infoPointTwo}
            infoPointThree={element.infoPointThree}
          />
        ))}
      </ul>
    </section>
  );
};
