import './Informations.scss';
import { PersonTile } from 'components/Shared/PersonTile/PersonTile';
import { PersonTilesList } from 'components/Shared/PersonTilesList/PersonTilesList';
import { filterPeopleById } from 'utils/People/filter-people-by-id/filter-people';
import peopleData from 'data/people.json';
import { Person } from 'types/Shared/Person.type';

export const Informations: React.FC = () => {
  return (
    <section className='informations'>
      <div className='informations__title-wrapper'>
        <h2 className='informations__title' id='horeb'>
          O nas
        </h2>
      </div>

      <div className='informations__container'>
        <article className='informations-single'>
          <h3 className='informations-single__title'>
            Nasi <span className='informations-single__title_decoration_background'>Duszpasterze</span>
          </h3>
          <PersonTilesList>
            <PersonTile person={filterPeopleById(peopleData as Person[], '1')} />
          </PersonTilesList>
        </article>
        <article className='informations-single'>
          <h3 className='informations-single__title'>
            Kadra <span className='informations-single__title_decoration_background'>Przewodnicząca</span>
          </h3>
          <PersonTilesList>
            <PersonTile person={filterPeopleById(peopleData as Person[], '3')} />
            <PersonTile person={filterPeopleById(peopleData as Person[], '4')} />
          </PersonTilesList>
        </article>
        <article className='informations-single'>
          <h3 className='informations-single__title'>
            Salezjańskie duszpasterstwo <span className='informations-single__title_decoration_background'>Horeb</span>
          </h3>
          <p className='informations-single__description'>
            Jesteśmy duszpasterstwem akademickim działającym w centrum Wrocławia przy salezjańskiej parafii pw. św.
            Michała Archanioła. Pragniemy, by nasza Wspólnota dawała każdej z tworzących ją osób możliwość wzrostu w
            wierze, świadomego postrzegania rzeczywistości oraz realizowania talentów, jakimi każdy z nas został
            niezasłużenie obdarowany dzięki łaskawości Boga. Nazwa: „HOREB” (hebr. suchy, samotny) pochodzi od góry, na
            której został powołany Mojżesz (Wj 3, l) i Jahwe ukazał się Eliaszowi (l Krl 19,8-14). Horeb to góra, po
            której marsz wymaga odpowiedniego przygotowania kondycyjnego, tak fizycznego, czysto ludzkiego, jak i
            duchowego. Każdy z nas ma swoją górę Horeb i swój szczyt, gdzie spotyka Boga, który wzywa i objawia się,
            wskazując pielgrzymowi właściwą i niepowtarzalną drogę życia. Warto przejść tę drogę w kontemplowaniu
            nieskończonej miłości Stwórcy, jak czynił to św. Jan Bosko założyciel Zgromadzenia Salezjańskiego. Wielką
            łaską jest żyć tak, jakby się widziało Niewidzialnego.
          </p>
        </article>

        <article className='informations-single'>
          <h3 className='informations-single__title'>
            Dlaczego <span className='informations-single__title_decoration_background'>Horeb</span>
          </h3>
          <p className='informations-single__description'>
            Każdy z nas jest kamieniem wznoszącym górę Horeb. Początkowo nieociosane i kanciaste kamyki z czasem
            nabierają kształtu, tworząc pokaźne wzniesienie. Horeb to też pustynia, przestrzeń spotkania człowieka z
            Bogiem. To miejsce rozmowy, nawiązania relacji z Nim i drugim człowiekiem. To ubogacenie wewnętrzne, z
            którym wychodzimy do świata, w którym żyjemy, by nieść Światło Chrystusa poprzez świadectwo życia. Aby to
            czynić, niezbędny jest sprawny kompas. Jest nim wiara! Zdrowa i dojrzała przemienia życie człowieka.
            Wypełnia je, nadając mu sens.
          </p>
        </article>

        <article className='informations-single'>
          <h3 className='informations-single__title'>
            Nasz <span className='informations-single__title_decoration_background'>Cel</span>
          </h3>
          <p className='informations-single__description'>
            Sprawić aby ludzie dołączający do Horebu żyli ewangelią. Żeby każdy z nas każdego dni pełnił wolę bożą.
            Pomóc zbudować żywą relację z Bogiem. Żeby każdy mężczyzna przez uczestnictwo w naszym duszpasterstwie
            stawał się podobny do Chrystusa (Christ like character).
          </p>
        </article>
      </div>
    </section>
  );
};
