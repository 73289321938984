import { ActivitiesHero } from 'components/Activities/ActivitiesHero/ActivitiesHero';
import { ExtraEvents } from 'components/Activities/ExtraEvents/ExtraEvents';
import { WeeklyPlan } from 'components/Activities/WeeklyPlan/WeeklyPlan';
import { Footer } from 'components/Shared/Footer/Footer';
import { Header } from 'components/Shared/Header/Header';
import './Activities.scss';
import { Helmet } from 'react-helmet';

export const Activities: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Aktywności</title>
        <meta name='description' content='Zobacz jak spędzamy nasz wolny czas' />
      </Helmet>
      <Header />
      <main className='activities'>
        <ActivitiesHero />
        <WeeklyPlan />
        <ExtraEvents />
      </main>
      <Footer />
    </>
  );
};
