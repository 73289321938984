import * as React from 'react';
import './NewsFeed.scss';

export const NewsFeed: React.FC = () => {
  return (
    <section className='news-feed'>
      <div className='news-feed__title-wrapper'>
        <h2 className='news-feed__title'>Nasze wydarzenia</h2>
      </div>
      <div className='widget-container' id='nasze-wydarzenia'>
        <iframe
          title='sociableKIT-widget'
          className='widget'
          src='https://widgets.sociablekit.com/facebook-page-posts/iframe/215123'
        ></iframe>
      </div>
    </section>
  );
};
