import * as React from 'react';
import './ActivitiesHero.scss';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'components/Shared/Button/Button';

export const ActivitiesHero: React.FC = () => {
  return (
    <section className='activities-hero-container'>
      <div className='activities-hero-container__wrapper'>
        <div className='activities-hero-container__content'>
          <h1 className='activities-hero-container__title'>
            Zobacz jak spędzamy nasz wolny{' '}
            <span className='activities-hero-container__title_decoration_background'>czas</span>
          </h1>
        </div>
        <div className='activities-hero-container__button-wrapper'>
          <Button href='#plan-tygodnia' text='Dowiedz się więcej' iconAtTheEnd={faAnglesDown} />
        </div>
      </div>
    </section>
  );
};
